<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="col-md-12" style="margin-top:-15px;padding: 14px;" >
      <div class="row" >
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Monitoramento de Alunos PNE</h2>
        </div>
      </div>

      <painelFiltro modelo="0" individual="1" @buscarRelatorio="buscarRelatorio" />

    </div>

    <div class="pm-card">
      n°, nome, data nascimento,filiação, ano letivo, CiD, Medicação Escolar(sim/não)
      <!--<div class="p-fluid formgrid grid" v-show="selecao.modelo ==='modelo01' && simples.turma_id >0 && pesquisar_modelo === true">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Aluno</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in this.relatorioALL" :key="r">
              <td>{{ r.nome +' '+ r.sobrenome }}</td>
              <td>
                <button class="btn btn-primary" @click="visualizarIndividual(r)" style="margin-top:10px;margin-right:5px;"
                  title="Visualizar">
                  Visualizar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>-->


    </div>


  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';

import painelFiltro from "./components/filtros/painelFiltro.vue"

export default defineComponent ({
  components:{
    painelFiltro
  },
  data(){
    return{
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      home: {icon: 'pi pi-home', to: '/relatorios/ficha-individual'},
      items_bread: [
        {label: 'Monitoramento de Alunos PNE'},
      ],
    }
  },
  async beforeMount() {},
  methods: {
    buscarRelatorio(info){
      console.log(info)
    }
  }
});
</script>

<style>

</style>
